<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="{'light': light}" viewBox="0 0 189 39.435">
    <path class="c1" fill="#fff" d="M242.57,43.793H229.28v24.8h5.795V62.079h7.5c5.7,0,9.392-3.623,9.392-9.226S248.363,43.793,242.57,43.793Zm0,12.633h-7.5V49.612h7.5c2.32,0,3.705,1.272,3.705,3.407C246.269,55.12,244.856,56.426,242.57,56.426Zm66.366-6.812h8.876V68.595h5.819V49.613h8.876V43.793H308.936Zm33.258,9.213h12.67V53.561h-12.67V49.613h15.139V43.793H336.407v24.8h20.959V62.775H342.193V58.827ZM266.6,43.46a3.149,3.149,0,0,0-2.758,1.817L249.819,68.595h6.626L266.4,51.713l3.332,5.615.74,1.244h-4.8L262.7,63.844h10.91l2.821,4.75h6.956L269.366,45.277A3.165,3.165,0,0,0,266.6,43.46ZM367.97,62.767V43.793h-5.819v24.8h18.932V62.767Zm-75.264-9.142-7.915-10h-7.766L289.6,58.971v9.463h5.848v-9.5l12.575-15.31h-7.4l-7.915,10Z" transform="translate(-192.084 -36.41)"/>
    <path fill="#1f66b0" d="M100.379,103.064a6.652,6.652,0,0,0,1.674-4,6.57,6.57,0,0,0-1.222-4.319,6.114,6.114,0,0,0-.464-.568L94.05,98.619Z" transform="translate(-78.792 -78.901)"/>
    <path class="c1" fill="#fff" d="M15.258,19.72l6.319-4.439a6.564,6.564,0,0,0-1.119-1L.19.025a.12.12,0,0,0-.19.1v8.8a.12.12,0,0,0,.052.1L1.582,10.1l13.676,9.615Z" transform="translate(0 -0.002)"/>
    <path fill="#1fb1e6" d="M15.258,121.54.052,132.231a.122.122,0,0,0-.052.1v8.808a.122.122,0,0,0,.19.1l20.268-14.249a6.6,6.6,0,0,0,1.136-1Z" transform="translate(0 -101.823)"/>
  </svg>
</template>
<script>
export default {
  name: "LogoComponent",
  props: {
    light: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.c1{
  fill: #0D1427;
  transition: .4s ease-out;
}
.light{
  .c1 {
    fill: #fff;
  }
}
</style>